body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}

/* .main-container .content-area {
  padding-top: 100px;
} */
.PhoneInput input {
  width: 100%;
  border: none;
  outline: none;
}
.otp-input {
  width: 35px !important;
  height: 35px !important;
  max-width: 15%;
  border-radius: 10px;
  font-weight: 600;
}

.fullscreen-error-overlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.fullscreen-error-overlay .error-container {
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 80%;
  border-radius: 10px;
  background-color: rgb(77, 76, 76);
  color: white;
}
.fullscreen-error-overlay .error-container p {
  margin: 0;
  text-align: center;
  text-justify: center;
}
.cursor-pointer {
  cursor: pointer;
}

.preloader-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 0px;
  width: 100vw;
  z-index: 9999;
  /* background: #f2f4f7; */
  backdrop-filter: blur(2px);
  /* margin-left: 240px; */
  margin-top: 63px;
  min-height: calc(100vh - 63px);
}

.phone-input {
  display: flex !important;
}
.phone-input input {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  margin-left: 36px !important;
  padding: 10px !important;
  font-size: 16px;
}
.phone-input input:focus {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
}

.w-tables {
  border: 1px solid #ededed;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);
}

table.dataTable {
  margin-bottom: 0px !important;
}

@media (max-width: 767.98px) {
  table.dataTable {
    width: 862px;
  }
}
.dataTables_paginate {
  margin: 12px !important;
}

.dataTables_length {
  padding: 15px 20px;
}

.dataTables_length select {
  height: 35px;
  min-width: 53px;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1d82f5 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #fcbd01 !important;
}

.border-danger {
  border-color: #d30000 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 1.3rem;
  padding-left: 1rem;
}

@media (max-width: 767.98px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: start !important;
  }
}
.dataTables_paginate {
  margin: 12px !important;
}
.footer-section {
  color: white;
}

.contact-slider {
  background-image: url("assets/images/cont-img.png");
  width: 100%;
  height: 314px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
}

.contfrom-section {
  width: 100%;
  height: auto;
  padding: 50px;
  background-color: #e6e6e6;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .contact-slider {
    width: 100%;
    height: 84px;
  }
  .contfrom-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .formtext h3 {
    font-size: 25px;
    font-weight: 600;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contfrom-section {
    width: 100%;
    height: auto;
    padding: 30px;
    background-color: #e6e6e6;
  }
}


.dots {
  border: 0;
  border-radius: 50%;
  height: 6px;
  padding: 0;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 18px;
  background-color: #42AD57;
}

.dots {
  /* Apply animation to create a pulsating effect */
  -webkit-animation: shadow-pulse 1s infinite; /* For webkit browsers */
  animation: shadow-pulse 1s infinite; /* Standard animation property */

  /* Define the size and style of the dots */
  font-size: 10px; /* Size of the dots */
  line-height: 15px; /* Spacing between the lines */
  width: 5px; /* Width of the dots */
}

/* Keyframes for the animation */
@-webkit-keyframes shadow-pulse {
  0% {
    /* At the start of the animation, the box shadow is fully opaque */
    box-shadow: 0 0 0 0 rgba(0, 177, 255, 0.2);
  }
  100% {
    /* At the end of the animation, the box shadow becomes transparent */
    box-shadow: 0 0 0 15px transparent;
  }
}

@keyframes shadow-pulse {
  0% {
    /* At the start of the animation, the box shadow is fully opaque */
    box-shadow: 0 0 0 0 rgba(0, 177, 255, 0.2);
  }
  100% {
    /* At the end of the animation, the box shadow becomes transparent */
    box-shadow: 0 0 0 15px transparent;
  }
}


/* For WebKit browsers */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track (the empty area where the thumb scrolls) */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

