.body {
    font-family: "Poppins", sans-serif;
}

p {
    font-family: "Poppins", sans-serif;
    line-height: 1.7em;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 55px;
    line-height: 1.7em;
    color: #00A699;
    font-weight: 500;
}

h2 {
    font-size: 36px;
    color: #00A699;
    line-height: 1.7em;
    font-weight: 500;
}

h3 {
    font-size: 28px;
    color: #00A699;
    line-height: 1.7em;
    font-weight: 500;
}

h4 {
    font-size: 22px;
    color: #000000;
    line-height: 1.7em;
    font-weight: 500;
}

h5 {
    font-size: 20px;
    font-weight: 500;
    color: #00A699;
}

h6 {
    font-size: 16px;
    font-weight: 500;
    color: #00A699;
}


img {
    width: 100%;
    height: auto;
    margin: auto;
}

#logsedf {
    width: 65%;
}

.axistify-header {
    background-color: #00A699;
    width: 100%;
    height: auto;
    padding: 0px 0px;
}
.tophead-text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    float: left;
}
.ico-col {
    color: #fff;
    font-size: 12px;
}
.element {
    float: right;
}
.element ul {
    margin-top: 0px;
    margin-bottom: 4px;
    color: #fff;
}
.header-top-nav.list-inline li {
    display: inline-block;
    font-size: 12px;
}


.navbar-nav .nav-link {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7em;
    padding: 6px 25px !important;
    text-transform: uppercase;
}

.navbar-brand {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.btn-outline-light:hover{
    color: #000;
}
.cabtify-header-section{
    background-color: #00000080;
    position: relative;
    z-index: -0;
}
.slider-section{
    background-image: url(../new-images/sliderimg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 260px 40px;
    margin-top: -70px;
}
.headerslider h4{
    color: #FFFFFF;
    font-size: 18px;
}
.headerslider p{
    color: #fff;
    line-height: 1.7em;
}
.btn-secondary{
    background-color: #00A699 !important;
}
.abt-section{
    padding: 40px;
}
.abttext p{
    line-height: 2.1em;
}
.ser-section{
    padding: 40px;
    background: #ECECEC;
}
.iconimg{
    text-align: center;
}
.iconimg {
    margin-top: -20px;
    position: relative;
}
.iconimg img{
    width: 55px;
}
.sercardtext{
    text-align: center;
}
.iconimgserv img{
    width: 55px;
}
.icon-img img{
    width: 55px;
}
.iconimgs img{
    width: 41px;
} 
.home-app-section {
    height: auto;
    padding: 25px 25px 0;
    width: 100%;
}
.downloadapp img {
    height: auto;
    margin: auto;
    max-width: 100%;
    width:50%;
}
.downloadapp {
    text-align: center;
}
.cont-section{
    background-color: #00A699;
    padding: 40px;
}
.con-text{
    text-align: center;
}
.con-text h2{
    color: #323232;
}
.con-text p{
    color: #fff;
    font-weight: 500;
}
.footer-section{
    background-color: #323232;
    width: 100%;
    height: auto;
    padding: 50px 50px 10px 50px;
}
#footerlogo{
    max-width: 100%;
    width: 60%;
    height: auto;
    margin: auto;
}
.footer-menu h4 {
    color: #fff;
    font-family: "Inter" , sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.footer-menu ul {
    padding: 0px 15px;
}
.footer-menu ul li {
    list-style: none;
    padding: 5px 5px;
}
#foot-icon {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}
.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    padding-left: 15px;
}
.footer-border{
    margin: 30px 0px 15px 0px;
    border-bottom: 2px solid #fff;
}
.footer-botm{
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;
}
.fbotm-text p{
    color: #00A699;
    font-family: "Inter" , sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 25.005px */
    text-transform: capitalize;
}
.fbotm-text ul{
    padding: 0px 15px;
}
.fbotm-text ul li{
    list-style: none;
    display: inline;
    padding: 0px 10px;
}
.fbotm-text ul li a{
    color: #00A699;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
}
.footer-soc ul{
    padding: 0px 0px;
}
.footer-soc ul li{
    list-style: none;
    display: inline;
    padding: 0px 5px;
}
.footer-soc ul li a{
    color: #00A699;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}
.fbotm-text{
    padding-top: 6px;
}
.pagesbanner-section{
    background-image: url(../new-images/pagesbanner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
}
.cabtify-headerpages-section{
    background-color: #000000;
}
.formbg {
    background-color: #EFEFEF;
    border: 1px solid #272525;
    border-radius: 8px;
    padding: 20px;
}
.cont-text h3{
    color: #272525;
}

.cabtify-header-img{
    background-color: black !important;
}


.logo-black{
    width: 100% !important;
    height: 70px !important;
  }





@media screen and (min-width: 769px) and (max-width: 991px) {
    .navbar .navbar-brand {
        width: 70%;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: center;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: #fff !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
    }

    a.nav-link {
        margin-left: 0;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        color: #fff !important;
        height: 37px;
        padding: 23px 0;
        background-color: #000000 !important;
        text-align: left;
        border-bottom: 1px solid #fff;
    }

    .navbar-brand {
        font-weight: 300;
        font-size: 10px;
    }

    #logsedf {
        width: 25%;
    }
    .abt-section {
        padding: 20px;
    }
    .ser-section{
        padding: 20px;
    }
    .home-app-section {
        padding: 20px 20px 0;
    }
    .cont-section {
        padding: 20px;
    }
    h3 {
        font-size: 24px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    }
    p {
        font-family: "Poppins", sans-serif;
        line-height: 1.5em;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
    }
    .bannnertext h1 {
        font-size: 40px;
        line-height: 1.7em;
        color: #00A699;
        font-weight: 500;
    }
    .pagesbanner-section {
        padding: 30px;
    }
    h2 {
        font-size: 28px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    }
    .abttext p {
        line-height: 1.7em;
    }
    h4 {
        font-size: 18px;
        color: #000000;
        line-height: 1.7em;
        font-weight: 500;
    }
    .slider-section {
        background-image: url(../new-images/sliderimg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 130px 20px 70px 20px;
        margin-top: -70px;
    }
    .footer-section {
        background-color: #323232;
        width: 100%;
        height: auto;
        padding: 20px 20px 10px 20px;
    }
    .fbotm-text p {
        color: #00A699;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    .fbotm-text ul li a {
        color: #00A699;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
    }
    .footer-menu ul li a {
        color: #fff;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
        padding-left: 15px;
    }
    

}


@media screen and (min-width: 481px) and (max-width: 768px) {
    .navbar .navbar-brand {
        width: 70%;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: center;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: #fff !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
    }

    a.nav-link {
        margin-left: 0;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        color: #fff !important;
        height: 37px;
        padding: 23px 0;
        background-color: #000000 !important;
        text-align: left;
        border-bottom: 1px solid #fff;
    }

    .navbar-brand {
        font-weight: 300;
        font-size: 10px;
    }

    #logsedf {
        width: 25%;
    }
    .abt-section {
        padding: 20px;
    }
    .ser-section{
        padding: 20px;
    }
    .home-app-section {
        padding: 20px 20px 0;
    }
    .cont-section {
        padding: 20px;
    }
    h3 {
        font-size: 24px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    }
    p {
        font-family: "Poppins", sans-serif;
        line-height: 1.5em;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
    }
    .bannnertext h1 {
        font-size: 40px;
        line-height: 1.7em;
        color: #00A699;
        font-weight: 500;
    }
    .pagesbanner-section {
        padding: 30px;
    }
    h2 {
        font-size: 28px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    }
    .abttext p {
        line-height: 1.7em;
    }
    h4 {
        font-size: 18px;
        color: #000000;
        line-height: 1.7em;
        font-weight: 500;
    }
    .slider-section {
        background-image: url(../new-images/sliderimg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 130px 20px 70px 20px;
        margin-top: -70px;
    }
    .footer-section {
        background-color: #323232;
        width: 100%;
        height: auto;
        padding: 20px 20px 10px 20px;
    }
    .fbotm-text p {
        color: #00A699;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    .fbotm-text ul li a {
        color: #00A699;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
    }
    .footer-menu ul li a {
        color: #fff;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
        padding-left: 15px;
    }

   

    
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .navbar .navbar-brand {
        width: 70%;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: center;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: #EFEFEF !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
    }

    a.nav-link {
        margin-left: 0;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        color: #fff !important;
        height: 37px;
        padding: 23px 0;
        background-color: #000000 !important;
        text-align: start;
        border-bottom: 1px solid #fff;
    }

    .navbar-brand {
        font-weight: 300;
        font-size: 10px;
    }

    #logsedf {
        width: 50%;
    }
    .abt-section {
        padding: 15px;
    }
    .ser-section{
        padding: 15px;
    }
    .home-app-section {
        padding: 15px 15px 0;
    }
    .cont-section {
        padding: 15px;
    }
    .downloadapp img {
        height: auto;
        margin: auto;
        max-width: 100%;
        width: 100%;
    }
    .footer-section {
        background-color: #323232;
        width: 100%;
        height: auto;
        padding: 15px 15px 10px 15px;
    }
    .fbotm-text p {
        color: #00A699;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    .fbotm-text ul li a {
        color: #00A699;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", sans-serif;
    }
   
    .bannnertext h1{
        font-size: 30px;
    } 
    .pagesbanner-section {
        padding: 20px;
    }  
    h2 {
        font-size: 24px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    }
    .abttext p {
        line-height: 1.7em;
    }
    p {
        font-family: "Poppins", sans-serif;
        line-height: 1.7em;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
    }
    h3 {
        font-size: 22px;
        color: #00A699;
        line-height: 1.7em;
        font-weight: 500;
    } 
    .slider-section {
        padding: 100px 15px 50px 15px;
        margin-top: -70px;
    }
    h1 {
        font-size: 30px;
        line-height: 1.7em;
        color: #00A699;
        font-weight: 500;
    }
    .headerslider h4 {
        color: #FFFFFF;
        font-size: 14px;
    }
    h4 {
        font-size: 20px;
        color: #000000;
        line-height: 1.7em;
        font-weight: 500;
    }

    .footer-botm{
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
        gap: 10;
        align-items: center;
    }
   
}

@media only screen and (min-width: 992px) and (max-width: 1380px) {

    .navbar-nav .nav-link {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.7em;
        padding: 6px 20px !important;
        text-transform: uppercase;
    }

    #logsedf {
        width: 52%;
    }
    p {
        font-family: "Poppins", sans-serif;
        line-height: 1.7em;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
    }
    h5 {
        font-size: 16px;
        font-weight: 500;
        color: #00A699;
    }
    .slider-section {
        background-image: url(../new-images/sliderimg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 160px 40px;
        margin-top: -70px;
    }
    .abttext p {
        line-height: 1.8em;
    }
    
    
    

}