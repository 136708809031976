.profile-card {
  background: #4FDD6A  ;
  overflow: hidden;
  padding: 37px 0;
  position: relative;
}

.profile-card:before {
  background: #27ac3f;
  border-radius: 4px;
  content: "";
  height: 120px;
  position: absolute;
  right: -63px;
  top: -3px;
  transform: rotate(36deg);
  width: 120px;
}

.profile-card:after {
  background: #27ac3f;
  border-radius: 35px;
  bottom: 9px;
  content: "";
  height: 125px;
  left: -62px;
  position: absolute;
  transform: rotate(28deg);
  width: 126px;
}


.avatar-100 {
    height: 100px;
    min-width: 100px;
    width: 100px;
}


.pro-content {
    background: #fff;
    left: 20px;
    margin-top: -55px;
    padding: 20px;
    position: absolute;
    right: 20px;
}

.p-icon {
    background: #bed8c3;
    border-radius: 4px;
    color: #323232  ;
    font-size: 26px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    width: 45px;
}

.eml {
    word-break: break-all;
}
.profile-box {
    background: #f7f9ff;
    border-radius: 0 0 4px 4px;
    padding-bottom: 240px;
    position: relative;
}

